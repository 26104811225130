<template>
  <b-card
    :img-src="product.images.length>0?product.images[0]:require('@/assets/images/blank/no_image.png')"
    img-alt="product.name"
    img-top
    class="mb-3 shadow-sm product-card"
    :class="{ 'sold-out': product.soldOut }"
  >
    <div class="d-flex justify-content-between align-items-start">
      <h5 class="mb-1">
        {{
            product.optionNameTranslate === 'multiple'
                  ? showFromCurrentLanguage(product.nameTranslate)
                  : product.name
              }}
      </h5>
      <b-badge
        v-if="product.soldOut"
        variant="danger"
      >
        {{ $t('Sold Out') }}
      </b-badge>
    </div>
    <!-- <p class="text-muted mb-2">
      {{ product.detail }}
    </p> -->
    <div class="d-flex justify-content-between align-items-center">
      <div>
        <span class="text-success font-weight-bold">
          {{ formatPrice(product.price) }} ฿
        </span>
        <span
          v-if="product.afterPrice > product.price"
          class="text-muted ml-2"
          style="text-decoration: line-through;"
        >
          {{ formatPrice(product.afterPrice) }} ฿
        </span>
      </div>

    </div>
  </b-card>
</template>

<script>
import {
  BFormGroup,
  BCard, BRow, BCol,
  BTab,
  BTabs,
  BImg,
  BAvatar,
  BFormInput, BButton,
  BTable, BPagination, BDropdown, BOverlay,
  BDropdownItem,
} from 'bootstrap-vue'

export default {
  name: 'ProductCard',
  components: {
    BFormGroup,
    BTab,
    BTabs,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,

    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,

  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatPrice(value) {
      return value.toLocaleString('th-TH', { minimumFractionDigits: 0 })
    },
        showFromCurrentLanguage(data) {
      if (data) {
        const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
        if (indexLang > -1) {
          return data[indexLang].value
        }
        return ''
      }
      return ''
    },
  },
}
</script>

  <style scoped>
  .product-card {
    cursor: pointer;
    transition: transform 0.2s;
  }
  .product-card:hover {
    transform: scale(1.02);
  }
  .sold-out {
    opacity: 0.6;
    pointer-events: none;
  }
  </style>
