import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respData: null,
    previewProducts: [],
  },
  getters: {
  },
  mutations: {
    GET(state, payload) {
      state.respData = payload
    },
    ADD(state, payload) {
      state.respData.data.unshift(payload)
    },
    REMOVE(state, id) {
      if (state.respData != null) {
        const ItemIndex = state.respData.data.findIndex(p => p._id === id)
        state.respData.data.splice(ItemIndex, 1)
        // eslint-disable-next-line operator-assignment
        state.respData.max = state.respData.max - 1
      }
    },
    PREVIEW_PRODUCTS(state, payload) {
      state.previewProducts = payload
    },
  },
  actions: {
    get({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/flashSale/get?pageSize=${item.pageSize}&currentPage=${item.currentPage}&searchQuery=${item.searchQuery}&typeTime=${item.typeTime}&selectedDate=${item.selectedDate}`)
          .then(response => {
            commit('GET', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    getProducts({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/application/eCommerce/flashSale/getProducts?id=${item.id}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    getPreview({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/flashSale/getPreview', item)
          .then(response => {
            commit('PREVIEW_PRODUCTS', response.data.data)

            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    add({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/application/eCommerce/flashSale/add', item)
          .then(response => {
            commit('ADD', response.data.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    update({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/flashSale/update', item)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    delete({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/application/eCommerce/flashSale/delete', item)
          .then(response => {
            commit('REMOVE', item.id)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
