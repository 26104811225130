<template>
  <b-modal
    :visible="isModalActive"
    :title="$t('Preview Flash Sale')"
    ok-title="Accept"
    size="lg"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <div class="m-2">

      <b-row>
        <b-col
          v-for="productData in previewProducts"
          :key="productData._id"
          md="4"
        >
          <ProductCard :product="productData" />
        </b-col>
      </b-row>
    </div>

    <template #modal-footer>
      <div class="w-100">

        <div class="float-right" />

      </div>
    </template>
  </b-modal>
</template>

<script>

import {
  BModal,
  BRow,
  BCol,
  BTabs,
  BTab,
  BCardText,
  BFormCheckbox,
  BButton,
  BFormInput,
  BFormGroup,
  BOverlay,

} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import ProductCard from '../components/ProductCard.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    BTabs,
    BTab,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    BFormGroup,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ProductCard,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => { },
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      currentDate: null,
      typeTime: null,

      required,
    }
  },
  computed: {

    previewProducts() {
      return store.state[this.storeModuleName].previewProducts != null ? store.state[this.storeModuleName].previewProducts : []
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          // this.$refs.formData.reset()
          this.initValues()
        } else {
          const {
            currentDate,
            typeTime,
          } = this.data
          this.currentDate = currentDate
          this.typeTime = typeTime
          this.getPreview()
        }
      }
    },
  },
  methods: {
    initValues() {

    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },

    getPreview() {
      store
        .dispatch(`${this.storeModuleName}/getPreview`, { currentDate: this.currentDate, typeTime: this.typeTime })
      // eslint-disable-next-line no-unused-vars
        .then(result => {

          //
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // console.log('fetchUsers Error : ', error)
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>
